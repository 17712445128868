import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';

function LearnMoreModal(props: any) {
    return (
        <Modal className="" 
            {...props}
            aria-labelledby="contained-modal-title-vcenter" size="lg"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    My Product Lists
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-4">
                        <p>My Product Lists allows you to:</p>
                        <ul>
                            <li>Create and manage your own Product Lists</li>
                            <li>Add notes</li>
                            <li>Duplicate lists</li>
                            <li>Share</li>
                            <li>Upload parts</li>
                            <li>Download product lists and product specifications</li>
                            <li>Request a quote</li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <ReactPlayer width="90%" height="90%" url='https://play.vidyard.com/Sa7G3SZDrgxZut2wstuhBu.jpg' />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>

    );
}
export default LearnMoreModal;