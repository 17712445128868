import { ProductData, ProductDataResult } from "./ProductData";

export class ConfigurationDropDown {

    index: number;
    length: string;
    quantity: string;
    isQuantity: boolean;
    cableTypes: Array<ProductDataResult>;
    selectedPartNumber: string;

    constructor() {
        this.index = 0;
        this.length = '0';
        this.quantity = '';
        this.isQuantity = false;
        this.selectedPartNumber = '';
        this.cableTypes = new Array<ProductDataResult>()
    }
}