import React from "react";

export default class ManageToken {

    public GetToken() {
        return window.sessionStorage.getItem("AuthorizationToken");
    };

    public SetToken(accessToken:any) {
        return window.sessionStorage.setItem("AuthorizationToken", accessToken);
    }
}