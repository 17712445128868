import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { LineItem, MyProductListCart, MyProductListLineItem } from "../../Models/MyProductListCart";
import { ProductDataResult } from "../../Models/ProductData";
import { AuthProvider, useAuth, hasAuthParams } from "react-oidc-context";
import ManageToken from "../../Token";
import { ConfProductProps } from "../../Models/ConfProductProps";
import { ConfigurationDropDown } from "../../Models/ConfigurationDropDown";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { msalConfig } from "../../authConfig";
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { AuthenticatedTemplate } from "@azure/msal-react";

export function MyProductList(props: any) {

    const [allCarts, setAllCarts] = useState<MyProductListCart>()
    const [productConfiguration, setProductConfiguration] = useState<Array<ConfProductProps>>()
    const selectedCarts = useRef<Array<string>>([])
    const productConfigsArr = useRef<Array<ConfProductProps>>([])
    const productConfigsArrApiCount = useRef<Array<ConfProductProps>>([])
    const [generatedBom, setGeneratedBom] = useState<Array<ProductDataResult>>([])
    const [recommendedProducts, setRecommendedProducts] = useState<Array<ProductDataResult>>([])
    const [additionalProducts, setAdditionalProducts] = useState<Array<ProductDataResult>>([])

    const [smAlert, setSmAlert] = useState(false);
    const [smAlertMessageTitle, setSmAlertMessageTitle] = useState("");
    const [smAlertMessageDesc, setSmAlertMessageDesc] = useState("");
    const [mplLink, setMplLink] = useState("");

    const [createProductListAlert, setCreateProductListAlert] = useState(false);
    const [addNameAlert, setAddNameAlert] = useState(false);
    const [mplAddedSuccess, setMplAddedSuccess] = useState(false);
    const [createProductListSuccess, setCreateProductListSuccess] = useState(false);
    const [hideSpinner, setHideSpinner] = useState(true);
    const [disableCreateProductList, setDisableCreateProductList] = useState(false);
    const productListName = useRef("");
    const token = new ManageToken();
    const isAuthCalled = useRef(false)
    const [lengthAndCableType, setLengthAndCableType] = useState<Array<ConfigurationDropDown>>([])

    const { instance, accounts, inProgress } = useMsal();
    const msalInstance = props.b2cMsalInstance;
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (props.show === true) {
            //getMyProductListCarts()
            setMplLink(process.env.REACT_APP_SERVER_URL_MPL_PAGE ? process.env.REACT_APP_SERVER_URL_MPL_PAGE.toString() : "")
            setSmAlert(false)
            setCreateProductListAlert(false)
            setMplAddedSuccess(false)
            setCreateProductListSuccess(false)
            setAddNameAlert(false)
            setLengthAndCableType(props.lengthAndCableType)
            productListName.current = ''
        }
    }, [props.show])

    useEffect(() => {
        setGeneratedBom(props.generatedBom)
    }, [props.generatedBom])

    useEffect(() => {
        setRecommendedProducts(props.recommendedProducts)
    }, [props.recommendedProducts])

    useEffect(() => {
        setAdditionalProducts(props.additionalProducts)
    }, [props.additionalProducts])

    function onProjectNameChanged(e: any) {
        console.log(e.target.value)
        productListName.current = e.target.value
    }

    function getMyProductListCarts() {
        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_GET_MYCARTS ? process.env.REACT_APP_SERVER_URL_GET_MYCARTS.toString() : ""
        const getData = async () => await fetch(submitRequestUrl + 'rohith.kunchamwar@commscope.com', {})
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result);
                    let products = new MyProductListCart()
                    setAllCarts(result)
                },
                (error) => {
                    console.log(error);
                }
            );
        getData();
    }

    function getConfigurableProductsConfiguration(partId: any) {
        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_GET_CONFIGURABLEPRODUCTCONFIG ? process.env.REACT_APP_SERVER_URL_GET_CONFIGURABLEPRODUCTCONFIG.toString() : ""


        const requestOptions = {
            method: 'GET',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
        };
        const getData = async () => await fetch(submitRequestUrl + partId, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    //console.log(result);

                    let tempApiCountArr = []
                    productConfigsArrApiCount.current.push(result)

                    for (let i = 0; i < productConfigsArrApiCount.current.length; i++) {
                        tempApiCountArr.push(productConfigsArrApiCount.current[i])
                    }

                    let resultArr: ConfProductProps[] = result
                    for (let i = 0; i < resultArr.length; i++) {
                        productConfigsArr.current.push(resultArr[i])

                    }
                    setProductConfiguration(new Array<ConfProductProps>())
                    setProductConfiguration(tempApiCountArr)
                },
                (error) => {
                    setHideSpinner(true)
                    setDisableCreateProductList(false)

                    setCreateProductListSuccess(false)
                    setSmAlert(false)
                    setMplAddedSuccess(false)
                    setCreateProductListAlert(true)
                }
            );
        getData();
    }


    useEffect(() => {
        if (props.lengthAndCableType !== undefined && props.lengthAndCableType.length > 0
            && productConfigsArrApiCount?.current.length === props.lengthAndCableType.length) {

            onClickAddToList()
        }
    }, [productConfiguration])

    function getAllProductConfigurations() {
        productConfigsArr.current = new Array<ConfProductProps>()
        productConfigsArrApiCount.current = new Array<ConfProductProps>()

        for (let i = 0; i < props.lengthAndCableType.length; i++) {
            getConfigurableProductsConfiguration(props.lengthAndCableType[i].selectedPartNumber)
        }
    }

    function onChangeCartChecked(e: any, item: string, index: any) {
        if (e.target.checked === true) {
            selectedCarts.current.push(item)
        }
        else {
            const index = selectedCarts.current.indexOf(item);
            if (index > -1) { // only splice array when item is found
                selectedCarts.current.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }

    function onClickAddToList() {

        var postLineItemUrl = process.env.REACT_APP_SERVER_URL_POST_LINE_ITEMS ? process.env.REACT_APP_SERVER_URL_POST_LINE_ITEMS.toString() : ""
        let cartList = productListName.current
        let lineItems = new Array<LineItem>()

        for (let i = 0; i < generatedBom.length; i++) {
            let lineItem = new LineItem()
            lineItem.code = generatedBom[i].partNumber
            if (generatedBom[i].productType?.includes('Hybrid cable assembly')) {
                lineItem.quantity = generatedBom[i].length.toString()
                lineItem.code = generatedBom[i].pimInternalId

                let customLength = lineItem.quantity

                if (lineItem.quantity.length === 2) {
                    customLength = '0' + customLength
                }
                else if (lineItem.quantity.length === 1) {
                    customLength = '00' + customLength
                }

                let tempCordLegnth = 'Custom_cordlength|' + lineItem.quantity + '|' + customLength + '|' + lineItem.quantity
                let tempCustomUom = 'Custom_uom|m|M|'
                let tempCustomJacketColor = 'Custom_jacketcolor|J - Yellow|J|'
                let tempCustomBreakoutLength = 'Custom_breakoutlengthoption|'

                if (productConfiguration !== undefined) {
                    for (let j = 0; j < productConfiguration?.length; j++) {
                        if (productConfiguration[j].partId === generatedBom[i].title &&
                            productConfiguration[j].id === 'breakoutlengthoption') {
                            tempCustomBreakoutLength = tempCustomBreakoutLength + productConfiguration[j].enum[0].name + '|' + productConfiguration[j].enum[0].value + '|'
                        }
                    }
                }

                lineItem.ConfPart.push(tempCordLegnth)
                lineItem.ConfPart.push(tempCustomUom)
                lineItem.ConfPart.push(tempCustomJacketColor)
                lineItem.ConfPart.push(tempCustomBreakoutLength)

                lineItem.PartNumber = generatedBom[i].partNumber.split('|')[1].trim()
            }
            else {
                lineItem.quantity = generatedBom[i].quantity.toString()
            }
            lineItems.push(lineItem)
        }

        for (let i = 0; i < recommendedProducts.length; i++) {
            let lineItem = new LineItem()
            lineItem.code = recommendedProducts[i].partNumber
            if (recommendedProducts[i].productType?.includes('Hybrid cable assembly')) {
                lineItem.quantity = recommendedProducts[i].length.toString()
                lineItem.code = recommendedProducts[i].pimInternalId

                let customLength = lineItem.quantity

                if (lineItem.quantity.length === 2) {
                    customLength = '0' + customLength
                }
                else if (lineItem.quantity.length === 1) {
                    customLength = '00' + customLength
                }

                let tempCordLegnth = 'Custom_cordlength|' + lineItem.quantity + '|' + customLength + '|' + lineItem.quantity
                let tempCustomUom = 'Custom_uom|m|M|'
                let tempCustomJacketColor = 'Custom_jacketcolor|J - Yellow|J|'
                let tempCustomBreakoutLength = 'Custom_breakoutlengthoption|'

                if (productConfiguration !== undefined) {
                    for (let j = 0; j < productConfiguration?.length; j++) {
                        if (productConfiguration[j].partId === recommendedProducts[i].title &&
                            productConfiguration[j].id === 'breakoutlengthoption') {
                            tempCustomBreakoutLength = tempCustomBreakoutLength + productConfiguration[j].enum[0].name + '|' + productConfiguration[j].enum[0].value + '|'
                        }
                    }
                }

                lineItem.ConfPart.push(tempCordLegnth)
                lineItem.ConfPart.push(tempCustomUom)
                lineItem.ConfPart.push(tempCustomJacketColor)
                lineItem.ConfPart.push(tempCustomBreakoutLength)

                lineItem.PartNumber = generatedBom[i].partNumber.split('|')[1].trim()
            }
            else {
                lineItem.quantity = recommendedProducts[i].quantity.toString()
            }
            lineItems.push(lineItem)
        }

        for (let i = 0; i < additionalProducts.length; i++) {
            let lineItem = new LineItem()
            lineItem.code = additionalProducts[i].partNumber

            if (additionalProducts[i].productType?.includes('Hybrid cable assembly')) {
                lineItem.quantity = additionalProducts[i].length.toString()
                lineItem.code = additionalProducts[i].pimInternalId

                let customLength = lineItem.quantity

                if (lineItem.quantity.length === 2) {
                    customLength = '0' + customLength
                }
                else if (lineItem.quantity.length === 1) {
                    customLength = '00' + customLength
                }

                let tempCordLegnth = 'Custom_cordlength|' + lineItem.quantity + '|' + customLength + '|' + lineItem.quantity
                let tempCustomUom = 'Custom_uom|m|M|'
                let tempCustomJacketColor = 'Custom_jacketcolor|J - Yellow|J|'
                let tempCustomBreakoutLength = 'Custom_breakoutlengthoption|'

                if (productConfiguration !== undefined) {
                    for (let j = 0; j < productConfiguration?.length; j++) {
                        if (productConfiguration[j].partId === additionalProducts[i].title &&
                            productConfiguration[j].id === 'breakoutlengthoption') {
                            tempCustomBreakoutLength = tempCustomBreakoutLength + productConfiguration[j].enum[0].name + '|' + productConfiguration[j].enum[0].value + '|'
                        }
                    }
                }

                lineItem.ConfPart.push(tempCordLegnth)
                lineItem.ConfPart.push(tempCustomUom)
                lineItem.ConfPart.push(tempCustomJacketColor)
                lineItem.ConfPart.push(tempCustomBreakoutLength)

                lineItem.PartNumber = generatedBom[i].partNumber.split('|')[1].trim()
            }
            else {
                lineItem.quantity = additionalProducts[i].quantity.toString()
            }

            for (let j = 0; j < generatedBom.length; j++) {
                if (lineItem.code === generatedBom[j].partNumber) {
                    if (additionalProducts[i].productType?.includes('Hybrid cable assembly')) {
                        lineItem.quantity = ((additionalProducts[i].length as number) + (generatedBom[j].length as number)).toString()
                    }
                    else {
                        lineItem.quantity = ((additionalProducts[i].quantity as number) + (generatedBom[j].quantity as number)).toString()
                    }
                }
                else {

                }
            }

            lineItems.push(lineItem)
        }

        const request: MyProductListLineItem = {
            UserName: '',
            ProductListNames: [productListName.current],
            ItemList: lineItems
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + token.GetToken() },
            body: JSON.stringify(request)
        };

        fetch(postLineItemUrl, requestOptions)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.isSuccess === true) {
                        setMplAddedSuccess(true)
                        setSmAlert(false)
                        setCreateProductListSuccess(false)
                        setCreateProductListAlert(false)
                        setAddNameAlert(false)
                        setHideSpinner(true)
                        setDisableCreateProductList(false)

                    }
                    else {
                        setMplAddedSuccess(false)
                        setSmAlert(true)
                        setCreateProductListSuccess(false)
                        setCreateProductListAlert(false)
                        setAddNameAlert(false)
                        setHideSpinner(true)
                        setDisableCreateProductList(false)

                    }
                },
                (error) => {
                    setSmAlert(true)
                    setCreateProductListAlert(false)
                    setMplAddedSuccess(false)
                    setCreateProductListSuccess(false)
                    setAddNameAlert(false)
                    setHideSpinner(true)
                    setDisableCreateProductList(false)

                }
            );
    }

    const [apiData, setApiData] = useState(null);

    function onClickCreateNewProductList() {
        if (productListName.current.trim() !== '') {
            setAddNameAlert(false)
            setHideSpinner(false)
            setDisableCreateProductList(true)
            const clientID = process.env.REACT_APP_B2C_CLIENTID ? process.env.REACT_APP_B2C_CLIENTID.toString() : ""
            
            
            const accessTokenRequest = {
                authortiy: process.env.REACT_APP_B2C_SIGNUPSIGNINAUTHORITY ? process.env.REACT_APP_B2C_SIGNUPSIGNINAUTHORITY.toString() : "",
                scopes: [clientID, "offline_access", "openid", "profile"],
                account: accounts[0],
            };
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then((accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;
                    token.SetToken(accessToken)
                    // Call your API with token
                    callCreateProductList(accessToken)

                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance
                            .acquireTokenPopup(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                // Acquire token interactive success
                                let accessToken = accessTokenResponse.accessToken;
                                // Call your API with token
                                callCreateProductList(accessToken)
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                setCreateProductListAlert(true)
                                setSmAlert(false)
                                setMplAddedSuccess(false)
                                setCreateProductListSuccess(false)
                                setHideSpinner(true)
                                setDisableCreateProductList(false)
                            });
                    }
                    else {
                        instance
                            .acquireTokenRedirect(accessTokenRequest)
                            .then(function (accessTokenResponse) {
                                // Acquire token interactive success
                                let accessToken = accessTokenResponse;
                                // Call your API with token
                                callCreateProductList(accessToken)
                            })
                            .catch(function (error) {
                                // Acquire token interactive failure
                                setCreateProductListAlert(true)
                                setSmAlert(false)
                                setMplAddedSuccess(false)
                                setCreateProductListSuccess(false)
                                setHideSpinner(true)
                                setDisableCreateProductList(false)
                            });
                    }
                    console.log(error);
                });


        }
        else {
            setAddNameAlert(true)
        }
    }

    useEffect(() => {
        setIsAuthenticated(true)
    }, [props.accounts, props.isAuthenticated])


    function callCreateProductList(accessToken: any) {
        var postLineItemUrl = process.env.REACT_APP_SERVER_URL_GET_CREATEPRODUCTLIST ? process.env.REACT_APP_SERVER_URL_GET_CREATEPRODUCTLIST.toString() : ""

        const request = {
            UserName: '',
            ProjectName: productListName.current,
            ProjectNotes: ''
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'AUTHORIZATION': 'Bearer ' + accessToken },
            body: JSON.stringify(request)
        };

        fetch(postLineItemUrl, requestOptions)
            .then(response => response.json())
            .then(
                (result) => {
                    if (result.isSuccess === 'true') {
                        setCreateProductListSuccess(false)
                        setSmAlert(false)
                        setMplAddedSuccess(false)
                        setCreateProductListAlert(false)
                        //getMyProductListCarts()
                        selectedCarts.current.push(productListName.current)
                        getAllProductConfigurations()
                        //onClickAddToList()
                    }
                    else {
                        setHideSpinner(true)
                        setDisableCreateProductList(false)

                        setCreateProductListSuccess(false)
                        setSmAlert(false)
                        setMplAddedSuccess(false)
                        setCreateProductListAlert(true)
                        //getMyProductListCarts()
                    }
                },
                (error) => {
                    setCreateProductListAlert(true)
                    setSmAlert(false)
                    setMplAddedSuccess(false)
                    setCreateProductListSuccess(false)
                    setHideSpinner(true)
                    setDisableCreateProductList(false)

                }
            );
    }




    //useEffect(() => {
    //    if (auth.isAuthenticated === true) {
    //        window.sessionStorage.setItem('isUserAuthenticated', "true")
    //        window.sessionStorage.setItem("AuthorizationToken", (auth.user) ? auth.user?.access_token : "");
    //    }
    //    else {
    //        console.log('');
    //        //callAuthentication()
    //    }
    //    props.isAuthenticatedFromMPL(auth.isAuthenticated)
    //}, [auth.isAuthenticated]);

    //useEffect(() => {
    //    if (!hasAuthParams() &&
    //        !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
    //        auth.signinRedirect();
    //    }
    //}, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    //switch (auth.activeNavigator) {
    //    case "signinSilent":
    //        return <div>Signing you in...</div>;
    //    case "signoutRedirect":
    //        return <div>Signing you out...</div>;
    //}




    //if (auth.error) {
    //    return <div>Oops... {auth.error.message}</div>;
    //}

    //if (auth.activeNavigator) {
    //    return <div>Signing you in/out...</div>;
    //}

    if (isAuthenticated) {

        return (
            <div>

                <div>
                    <Modal className=""
                        {...props}
                        aria-labelledby="contained-modal-title-vcenter" size="lg"
                        centered>
                        <Modal.Header>
                            <Modal.Title id="contained-modal-title-vcenter">
                                My Product Lists
                            </Modal.Title>

                        </Modal.Header>
                        <Modal.Body>
                            <div hidden={hideSpinner} className="spinner-border spinner-md text-primary" style={{ marginLeft: '45%', marginTop: '5%', position: 'absolute' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                            {/*<div className="row">*/}
                            {/*    <div className="col-md-12">*/}

                            {/*        <div className="custom-controls-stacked" style={{ overflow: "auto", width: "100%", height: "10rem" }}>*/}
                            {/*            {allCarts?.projects.map((item, index) => {*/}
                            {/*                return (*/}
                            {/*                    <label className='custom-control custom-checkbox'>*/}
                            {/*                        <input className='custom-control-input'*/}
                            {/*                            type="checkbox"*/}
                            {/*                            value={item} onChange={(e) => onChangeCartChecked(e, item, index)} />*/}
                            {/*                        <span className="custom-control-indicator"></span>*/}
                            {/*                        <span className="custom-control-description">{item}</span>*/}

                            {/*                    </label>*/}
                            {/*                )*/}
                            {/*            })}*/}

                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            {/*<div className="ml-0 mt-2">*/}
                            {/*    <button type="button" className="btn btn-primary" onClick={onClickAddToList}>Add to List</button>*/}
                            {/*    <a className="btn btn-link" style={{ fontWeight: 'bold' }} href={mplLink}>View My Product List</a>*/}

                            {/*</div>*/}
                            {/*{smAlert === true &&*/}
                            {/*    <p className="mt-2 text-danger">Your products were not added to the My Product List, please try again.</p>*/}
                            {/*}*/}
                            {/*{smAlert === false &&*/}
                            {/*    <p className="mt-2 text-danger" style={{ display: "none" }}>Your products were not added to the My Product List, please try again.</p>*/}
                            {/*}*/}
                            {/*{mplAddedSuccess === true &&*/}
                            {/*    <p className="mt-2 text-success">Your products have been Successfully added to My Product List.</p>*/}
                            {/*}*/}
                            {/*{mplAddedSuccess === false &&*/}
                            {/*    <p className="mt-2 text-success" style={{ display: "none" }}> Your products have been Successfully added to My Product List.</p>*/}
                            {/*}*/}
                            <div >
                                <p ><strong>Create New Product List for Constellation Products</strong></p>
                                <input type="text" className="form-control col-md-4" placeholder="Enter a Project Name"
                                    aria-describedby="helpBlock" onChange={onProjectNameChanged} />
                                <div className='mt-3'>
                                    <button type="button" disabled={disableCreateProductList} className="btn btn-primary mr-3" onClick={onClickCreateNewProductList}>Create New Product List</button>
                                    <a type="button" href={mplLink} target="_blank" className="btn btn-secondary">Go to My Product Lists</a>
                                </div>
                                {smAlert === true &&
                                    <p className="mt-2 text-danger">Your products were not added to the My Product List, please try again.</p>
                                }
                                {smAlert === false &&
                                    <p className="mt-2 text-danger" style={{ display: "none" }}>Your products were not added to the My Product List, please try again.</p>
                                }
                                {mplAddedSuccess === true &&
                                    <p className="mt-2 text-success">Your products have been Successfully added to My Product List.</p>
                                }
                                {mplAddedSuccess === false &&
                                    <p className="mt-2 text-success" style={{ display: "none" }}> Your products have been Successfully added to My Product List.</p>
                                }
                                {addNameAlert === true &&
                                    <p className="mt-2 text-danger">Please enter a Name for the Product List</p>
                                }
                                {addNameAlert === false &&
                                    <p className="mt-2 text-danger" style={{ display: "none" }}>Please enter a Name for the Product List</p>
                                }
                                {createProductListAlert === true &&
                                    <p className="mt-2 text-danger">Unable to create New Product List at this moment, please try again later.</p>
                                }
                                {createProductListAlert === false &&
                                    <p className="mt-2 text-danger" style={{ display: "none" }}>Unable to create New Product List at this moment, please try again later.</p>
                                }
                                {/*{createProductListSuccess === true &&*/}
                                {/*    <p className="mt-2 text-success">New Project created successfully.</p>*/}
                                {/*}*/}
                                {/*{createProductListSuccess === false &&*/}
                                {/*    <p className="mt-2 text-success" style={{ display: "none" }}>New Project created successfully.</p>*/}
                                {/*}*/}
                            </div>

                        </Modal.Body>
                        <Modal.Footer style={{ marginTop: "-2rem" }}>

                            <Button className='btn btn-dark' onClick={props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                </div>

            </div>
        );
    }

    return (
        <div className="App">
            {isAuthenticated &&
                <div>
                    <div>
                        <Modal show={smAlert} onHide={() => setSmAlert(false)}>
                            <Modal.Header>
                                <Modal.Title>{smAlertMessageTitle}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>{smAlertMessageDesc}</Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={() => setSmAlert(false)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal className=""
                            {...props}
                            aria-labelledby="contained-modal-title-vcenter" size="lg"
                            centered>
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    My Product Lists
                                </Modal.Title>

                            </Modal.Header>
                            <Modal.Body>

                                {/*<div className="row">*/}
                                {/*    <div className="col-md-12">*/}

                                {/*        <div className="custom-controls-stacked" style={{ overflow: "auto", width: "100%", height: "10rem" }}>*/}
                                {/*            {allCarts?.projects.map((item, index) => {*/}
                                {/*                return (*/}
                                {/*                    <label className='custom-control custom-checkbox'>*/}
                                {/*                        <input className='custom-control-input'*/}
                                {/*                            type="checkbox"*/}
                                {/*                            value={item} onChange={(e) => onChangeCartChecked(e, item, index)} />*/}
                                {/*                        <span className="custom-control-indicator"></span>*/}
                                {/*                        <span className="custom-control-description">{item}</span>*/}

                                {/*                    </label>*/}
                                {/*                )*/}
                                {/*            })}*/}

                                {/*        </div>*/}
                                {/*    </div>*/}

                                {/*</div>*/}
                                {/*<div className="ml-0 mt-2">*/}
                                {/*    <button type="button" className="btn btn-primary" onClick={onClickAddToList}>Add to List</button>*/}
                                {/*    <a className="btn btn-link" style={{ fontWeight: 'bold' }} href={mplLink}>View My Product List</a>*/}

                                {/*</div>*/}
                                {/*{smAlert === true &&*/}
                                {/*    <p className="mt-2 text-danger">Your products were not added to the My Product List, please try again.</p>*/}
                                {/*}*/}
                                {/*{smAlert === false &&*/}
                                {/*    <p className="mt-2 text-danger" style={{ display: "none" }}>Your products were not added to the My Product List, please try again.</p>*/}
                                {/*}*/}
                                {/*{mplAddedSuccess === true &&*/}
                                {/*    <p className="mt-2 text-success">Your products have been Successfully added to My Product List.</p>*/}
                                {/*}*/}
                                {/*{mplAddedSuccess === false &&*/}
                                {/*    <p className="mt-2 text-success" style={{ display: "none" }}> Your products have been Successfully added to My Product List.</p>*/}
                                {/*}*/}
                                <div >
                                    <p ><strong>Create New Product List for Constellation Products</strong></p>
                                    <input type="text" className="form-control col-md-4" placeholder="Enter a Project Name"
                                        aria-describedby="helpBlock" onChange={onProjectNameChanged} />

                                    <button type="button" className="btn btn-primary mt-3" onClick={onClickCreateNewProductList}>Create New Product List</button>
                                    {smAlert === true &&
                                        <p className="mt-2 text-danger">Your products were not added to the My Product List, please try again.</p>
                                    }
                                    {smAlert === false &&
                                        <p className="mt-2 text-danger" style={{ display: "none" }}>Your products were not added to the My Product List, please try again.</p>
                                    }
                                    {mplAddedSuccess === true &&
                                        <p className="mt-2 text-success">Your products have been Successfully added to My Product List.</p>
                                    }
                                    {mplAddedSuccess === false &&
                                        <p className="mt-2 text-success" style={{ display: "none" }}> Your products have been Successfully added to My Product List.</p>
                                    }
                                    {addNameAlert === true &&
                                        <p className="mt-2 text-danger">Please enter a Name for the Product List</p>
                                    }
                                    {addNameAlert === false &&
                                        <p className="mt-2 text-danger" style={{ display: "none" }}>Please enter a Name for the Product List</p>
                                    }
                                    {createProductListAlert === true &&
                                        <p className="mt-2 text-danger">Unable to create New Product List at this moment, please try again later.</p>
                                    }
                                    {createProductListAlert === false &&
                                        <p className="mt-2 text-danger" style={{ display: "none" }}>Unable to create New Product List at this moment, please try again later.</p>
                                    }
                                    {/*{createProductListSuccess === true &&*/}
                                    {/*    <p className="mt-2 text-success">New Project created successfully.</p>*/}
                                    {/*}*/}
                                    {/*{createProductListSuccess === false &&*/}
                                    {/*    <p className="mt-2 text-success" style={{ display: "none" }}>New Project created successfully.</p>*/}
                                    {/*}*/}
                                </div>

                            </Modal.Body>
                            <Modal.Footer style={{ marginTop: "-2rem" }}>

                                <Button onClick={props.onHide}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>

            }
        </div>

    );
}

