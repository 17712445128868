export class ProductData {
    Listing: Listing;
    constructor() {
        this.Listing = new Listing()
    }
}

export class Listing {
    Results: ProductDataResult[];
    constructor() {
        this.Results = new Array<ProductDataResult>()
    }
}

export class ProductDataResult {
    partNumber: string;
    partName: string;
    pimInternalId: string;
    summary: string;
    url: URL;
    ImageUrl: string;
    StatusSupportEnded: boolean;
    StatusDiscontinued: boolean;
    StatusIsFeatured: boolean;
    attributes: Attribute[];
    SpecificationUrl: string;
    RelatedDocumentsUrl: string;
    IsMyProductListItem: boolean;
    IsCommonVariant: boolean;
    ProductBrand: null | string;
    ProductSeries: null | string;
    productType: null | string;
    PartPricing: null;
    IsConfigurable: boolean;
    title: string;
    AvailableStockDetails: null;
    length: number
    quantity: number
    constructor() {
        this.partNumber = ""
        this.partName = ""
        this.summary = ""
        this.pimInternalId = ""
        this.ImageUrl = ""
        this.SpecificationUrl = ""
        this.RelatedDocumentsUrl = ""
        this.RelatedDocumentsUrl = ""
        this.ProductBrand = ""
        this.ProductSeries = ""
        this.productType = ""
        this.PartPricing = null
        this.title = ""
        this.AvailableStockDetails = null
        this.length = 0
        this.quantity = 0

        this.url = new URL()
        this.attributes = new Array<Attribute>()

        this.StatusDiscontinued = false
        this.StatusSupportEnded = false
        this.StatusIsFeatured = false
        this.IsMyProductListItem = false
        this.IsCommonVariant = false
        this.IsConfigurable = false
    }
}

export class Attribute {
    name: Name;
    displayName: Name;
    value: string;
    constructor() {
        this.name = Name.ClassPortfolio
        this.displayName = Name.ClassPortfolio
        this.value = ""
    }
}

export enum Name {
    ClassPortfolio = "Class: Portfolio",
    ClassProductType = "Class: Product Type",
    ClassRegionalAvailability = "Class: Regional Availability",
    ConnectorA = "Gen: Interface, Connector A",
    ConnectorB = "Gen: Interface, Connector B",
    TotalPorts = "Gen: Total Ports, quantity, front"
}

export enum IsDisplayBaseCodeText {
    N = "N",
}

export enum Portfolio {
    CommScope = "CommScope®",
}

export class URL {
    originalString: string;
    path: string;
    
    fragment: string;
    isAbsoluteUri: boolean;
    localPath: string;
    pathAndQuery: string;
    port: number;
    query: string;
    queryCollection: any[];
    segments: string[];
    userEscaped: boolean;
    userInfo: string;
    uri: string;

    constructor() {

        this.originalString = ""
        this.path = ""
        this.fragment = ""
        this.localPath = ""
        this.pathAndQuery = ""
        this.query = ""
        this.userInfo = ""
        this.uri = ""

        this.queryCollection = new Array()
        this.segments = new Array<string>()
        this.port = 0

        this.isAbsoluteUri = false
        this.userEscaped = false
    }
}
