import { useEffect, useRef, useState } from 'react';
import { ConfigurationDropDown } from '../../Models/ConfigurationDropDown';
import { Name, ProductDataResult } from '../../Models/ProductData';
import LearnMoreModal from '../Modals/LearnMoreModal';
import { MyProductList } from '../MyProductList/MyProductList';
import './Configurator.css';
//@ts-ignore
import * as XLSX from 'xlsx-styles';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import DownloadPDFButton from './DownloadPDFButton';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { AuthenticatedTemplate, useMsal, useIsAuthenticated, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';

export function Configurator(props: any) {

    const [numberOfConvergencePoints, setNumberOfConvergencePoints] = useState(0)
    const [newNumberOfConvergencePoints, setNewNumberOfConvergencePoints] = useState(0)
    const [uiUpdated, setUiUpdated] = useState<Array<ProductDataResult>>([])

    const [isErrorSelectionErrorVisible, setIsErrorSelectionErrorVisible] = useState(false)
    const [isBomVisible, setIsBomVisible] = useState(false)

    const allParts = useRef<Array<ProductDataResult>>([])
    const allAdditionalProdcuts = useRef<Array<ProductDataResult>>([])
    const selectedAdditionalProduct = useRef<ProductDataResult>()
    const [selectedAdditionalProductState, setSelectedAdditionalProductState] = useState<ProductDataResult>()

    const additionalPartSelectedCableType = useRef("")
    const isUserAuthenticated = useRef(false)

    const additionalPartSelectedLength = useRef(0)
    const additionalPartSelectedQuantity = useRef(0)
    const previousConvergencePoints = useRef(0)

    const [generatedBom, setGeneratedBom] = useState<Array<ProductDataResult>>([])
    const [recommendedProducts, setRecommendedProducts] = useState<Array<ProductDataResult>>([])
    const [additionalProducts, setAdditionalProducts] = useState<Array<ProductDataResult>>([])

    const [lengthAndCableType, setLengthAndCableType] = useState<Array<ConfigurationDropDown>>([])
    const [additionalProductsDropDown, setAdditionalProductsDropDown] = useState<ConfigurationDropDown>()


    const txtConvergencePoints = useRef<HTMLInputElement>(null);

    const [selectedCableTypeValueFromDropDown, setSelectedCableTypeValueFromDropDown] = useState('');

    const [showMyProductList, setShowMyProductList] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [learnMoreDesc, setLearnMoreDesc] = useState(false);

    const [isAdditionalProductCable, setIsAdditionalProductCable] = useState(false);
    const [isAdditionalProductQty, setIsAdditionalProductQty] = useState(false);
    const [isAdditionalProductLengthWarning, setIsAdditionalProductLengthWarning] = useState(false);

    const [smAlert, setSmAlert] = useState(false);
    const [smAlertMessageTitle, setSmAlertMessageTitle] = useState("");
    const [smAlertMessageDesc, setSmAlertMessageDesc] = useState("");

    const [requestAssistanceModalShow, setRequestAssistanceModalShow] = useState(false);
    const [supportPageLink, setSupportPageLink] = useState("");

    const handleClose = () => setSmAlert(false);

    //const ExcelFile = ReactExport.ExcelFile;
    //const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
    //const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

    function onConvergencePointsChanged(event: any) {
        setNumberOfConvergencePoints(event.target.value)
    }

    useEffect(() => {
        getConstellationPartsData()
        setSupportPageLink(process.env.REACT_APP_SERVER_URL_SUPPORT_PAGE ? process.env.REACT_APP_SERVER_URL_SUPPORT_PAGE.toString() : "")
        let localIsUserAuthenticated = window.sessionStorage.getItem('isUserAuthenticated')
    }, [])

    useEffect(() => {
        console.log(lengthAndCableType)
    }, lengthAndCableType)

    //var clientId = process.env.REACT_APP_SERVER_URL_CLIENT_ID ? process.env.REACT_APP_SERVER_URL_CLIENT_ID.toString() : ""
    //var clientSecret = process.env.REACT_APP_SERVER_URL_CLIENT_SECRET ? process.env.REACT_APP_SERVER_URL_CLIENT_SECRET.toString() : ""
    //var redirectUrl = process.env.REACT_APP_SERVER_URL_REDIRECT_URI ? process.env.REACT_APP_SERVER_URL_REDIRECT_URI.toString() : ""
    ////var redirectUrl = "https://webapp-reqaccess-web-dev.azurewebsites.net";
    //var authority = process.env.REACT_APP_SERVER_URL_AUTHORITY ? process.env.REACT_APP_SERVER_URL_AUTHORITY.toString() : ""
    //var authorizationEndpoint = process.env.REACT_APP_SERVER_URL_AUTHORIZATION_ENDPOINT ? process.env.REACT_APP_SERVER_URL_AUTHORIZATION_ENDPOINT.toString() : ""
    //var tokenEndpoint = process.env.REACT_APP_SERVER_URL_TOKEN_ENDPOINT ? process.env.REACT_APP_SERVER_URL_TOKEN_ENDPOINT.toString() : ""
    //var userInfoEndpoint = process.env.REACT_APP_SERVER_URL_USERINFO_ENDPOINT ? process.env.REACT_APP_SERVER_URL_USERINFO_ENDPOINT.toString() : ""
    //var postLogoutEndpoint = process.env.REACT_APP_SERVER_URL_POST_LOGOUT_ENDPOINT ? process.env.REACT_APP_SERVER_URL_POST_LOGOUT_ENDPOINT.toString() : ""

    //var oidcConfig = {
    //    //authority: "https://demo.duendesoftware.com/",
    //    //client_id: "interactive.public",
    //    //redirect_uri: "http://localhost:3000",
    //    //response_type: "code",
    //    //filterProtocolClaims: true,
    //    //loadUserInfo: true,
    //    //scope: "openid profile api",
    //    //automaticSilentRenew: false

    //    authority: authority,
    //    client_id: clientId,
    //    client_secret: clientSecret,
    //    redirect_uri: redirectUrl,
    //    response_type: "code",
    //    filterProtocolClaims: true,
    //    loadUserInfo: false,
    //    metadata: {
    //        authorization_endpoint: authorizationEndpoint,
    //        token_endpoint: tokenEndpoint

    //    },
    //    post_logout_redirect_uri: postLogoutEndpoint,
    //    scope: "openid CommScopeProfile",

    //    //automaticSilentRenew: false,
    //};



    const getAccessTokenExpiryTime = (number: undefined) => {
    }

    const onSignOutRedirect = () => {
        console.log()
    }


    useEffect(() => {

        //setAllValuesAfterLogin()

    }, [numberOfConvergencePoints])

    function requestAssistance(event: any) {
        setRequestAssistanceModalShow(true)
    }

    function setAllValuesAfterLogin() {
        if (numberOfConvergencePoints.toString() !== '') {

            setNewNumberOfConvergencePoints(numberOfConvergencePoints)

            const tempConfigruationDropDownArr = new Array<ConfigurationDropDown>()

            if (Number(previousConvergencePoints.current) > 0 && Number(previousConvergencePoints.current)
                <= Number(numberOfConvergencePoints)) {
                for (let i = 0; i < lengthAndCableType.length; i++) {
                    tempConfigruationDropDownArr.push(lengthAndCableType[i])
                }
                for (let i = 0; i < Number(numberOfConvergencePoints) - Number(previousConvergencePoints.current); i++) {
                    const tempDropDownValue = new ConfigurationDropDown()
                    tempDropDownValue.length = '0'
                    tempDropDownValue.index = i
                    tempDropDownValue.cableTypes = new Array<ProductDataResult>()
                    tempConfigruationDropDownArr.push(tempDropDownValue)
                }
                previousConvergencePoints.current = numberOfConvergencePoints
            }
            else if (Number(previousConvergencePoints.current) > 0 && Number(previousConvergencePoints.current)
                > Number(numberOfConvergencePoints)) {
                for (let i = 0; i < Number(numberOfConvergencePoints); i++) {
                    tempConfigruationDropDownArr.push(lengthAndCableType[i])
                }
                previousConvergencePoints.current = numberOfConvergencePoints
            }
            else {
                for (let i = 1; i <= Number(numberOfConvergencePoints); i++) {

                    const tempDropDownValue = new ConfigurationDropDown()
                    tempDropDownValue.length = '0'
                    tempDropDownValue.index = i
                    tempDropDownValue.cableTypes = new Array<ProductDataResult>()
                    tempConfigruationDropDownArr.push(tempDropDownValue)

                }
                previousConvergencePoints.current = numberOfConvergencePoints
            }

            setLengthAndCableType(tempConfigruationDropDownArr)
        }
    }

    function getConstellationPartsData() {

        var submitRequestUrl = process.env.REACT_APP_SERVER_URL_GET_ALLPARTS ? process.env.REACT_APP_SERVER_URL_GET_ALLPARTS.toString() : ""

        const getData = async () => await fetch(submitRequestUrl, {})
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);
                    let products = new Array<ProductDataResult>()
                    if (result !== null && result.listing != null) {
                        for (let i = 0; i < result.listing.results.length; i++) {
                            products.push(result.listing.results[i])
                        }
                    }
                    allParts.current = products
                    fillAdditionalProductsDropDown()
                },
                (error) => {
                    console.log(error);
                }
            );

        getData();
    }



    function resetConfiguration() {

        if (txtConvergencePoints.current !== null) {
            txtConvergencePoints.current.value = '0';
        }

        let newBom = new Array<ProductDataResult>()
        setGeneratedBom(newBom)


        for (let i = 0; i < allParts.current.length; i++) {
            //allParts.current[i].length = 0;
            //allParts.current[i].quantity = 0;
        }

        additionalPartSelectedLength.current = 0
        additionalPartSelectedCableType.current = ''
        setAdditionalProducts(newBom)
        setNumberOfConvergencePoints(0)

        const tempConfigruationDropDownArr = new Array<ConfigurationDropDown>()
        setLengthAndCableType(tempConfigruationDropDownArr)
        setNewNumberOfConvergencePoints(0)

        setIsErrorSelectionErrorVisible(false)
        setIsBomVisible(false)
    }

    function onChangeNumberOfConvergencePoints(event: any) {
        setNumberOfConvergencePoints(event.target.value)
    }

    function onClickCreateConvergencePoints(event: any) {
        setAllValuesAfterLogin()
    }

    function onSubmitConvergencePoints(e: any) {
        console.log()
        if (e.target.value.trim() === '') {

            if (previousConvergencePoints.current > 0) {
                setSmAlertMessageTitle("Confirmation");
                setSmAlertMessageDesc("Leaving the convergence points blank would reset the configuration. Would you like to Continue?");
                setSmAlert(true)
            }


        }
    }

    function onFocusConvergencePoints(e: any) {
        console.log()
        if (e.target.value <= 0) {
            e.target.value = ''
        }
    }

    function onFocusAdditionalPrdQty(e: any) {
        console.log()
        if (e.target.value <= 0) {
            e.target.value = ''
        }
    }

    function onSubmitadditionalPrdQty(e: any) {
        console.log()
        if (e.target.value <= 0) {
            e.target.value = ''
        }
    }

    function onSubmitCableTypeLength(e: any) {
        console.log()
        if (e.target.value.trim() === '') {
            e.target.value = '0'
        }
    }

    function onFocusCableTypeLength(e: any, index: any, changeVal: ConfigurationDropDown) {
        console.log()
        if (e.target.value <= 0) {
            e.target.value = ''
        }
    }

    function onChangeLength(e: any, index: any, changeVal: ConfigurationDropDown) {
        let length: number = e.target.value

        const changedLengthCableType = new ConfigurationDropDown()
        changedLengthCableType.index = changeVal.index
        changedLengthCableType.length = length.toString()

        if (Number(length) % 10 === 0 && length >= 10 && length <= 150) {
            changedLengthCableType.cableTypes = getTwoAndFourPairValues()
        }
        else if (Number(length) % 10 === 0 && length > 150 && length <= 350) {
            changedLengthCableType.cableTypes = getFourPairValues()
        }
        else if (Number(length) % 10 === 0 && length > 350 && length <= 550) {
            changedLengthCableType.cableTypes = getFourPairValues()
        }

        const tempConfigruationDropDownArr = []

        for (let i = 0; i < lengthAndCableType.length; i++) {
            if (index !== i) {

                const tempDropDownValue = new ConfigurationDropDown()
                tempDropDownValue.length = lengthAndCableType[i].length
                tempDropDownValue.index = i
                tempDropDownValue.cableTypes = lengthAndCableType[i].cableTypes
                tempDropDownValue.selectedPartNumber = lengthAndCableType[i].selectedPartNumber

                tempConfigruationDropDownArr.push(tempDropDownValue)
            }
            else if (index === i) {
                tempConfigruationDropDownArr.push(changedLengthCableType)
            }
        }

        setLengthAndCableType(tempConfigruationDropDownArr)
    }

    function onChangeCableType(e: any, index: any) {
        const tempConfigruationDropDownArr = []

        for (let i = 0; i < lengthAndCableType.length; i++) {
            const tempDropDownValue = new ConfigurationDropDown()
            tempDropDownValue.length = lengthAndCableType[i].length
            tempDropDownValue.index = i
            tempDropDownValue.cableTypes = lengthAndCableType[i].cableTypes

            if (index !== i) {
                tempDropDownValue.selectedPartNumber = lengthAndCableType[i].selectedPartNumber
            }
            else if (index === i) {
                tempDropDownValue.selectedPartNumber = e.target.value
            }

            tempConfigruationDropDownArr.push(tempDropDownValue)

        }

        setLengthAndCableType(tempConfigruationDropDownArr)

    }

    function getTwoAndFourPairValues() {
        const twoPairFourPair = new Array<ProductDataResult>()

        for (let i = 0; i < allParts.current.length; i++) {
            let currProd = allParts.current[i]
            if (currProd.summary.includes('2 Pair') || currProd.summary.includes('4 Pair')) {
                twoPairFourPair.push(currProd)
            }
            else {
                for (let j = 0; j < currProd.attributes.length; j++) {
                    let currAttribute = currProd.attributes[j]
                    if (currAttribute.name === "Class: Product Type" && currAttribute.value === "Hybrid cable assembly | 4-pair" || currAttribute.value === "Hybrid cable assembly | 2-pair") {
                        twoPairFourPair.push(currProd)
                    }
                }
            }
        }

        return twoPairFourPair
    }

    function getFourPairValues() {

        const fourPair = new Array<ProductDataResult>()

        for (let i = 0; i < allParts.current.length; i++) {
            let currProd = allParts.current[i]
            if (currProd.summary.includes('4 Pair')) {
                fourPair.push(currProd)
            }
            else {
                for (let j = 0; j < currProd.attributes.length; j++) {
                    let currAttribute = currProd.attributes[j]
                    if (currAttribute.name === "Class: Product Type" && currAttribute.value === "Hybrid cable assembly | 4-pair") {
                        fourPair.push(currProd)
                    }
                }
            }
        }

        return fourPair
    }


    function fillAdditionalProductsDropDown() {
        let tempConfigruationDropDownArr = []
        let tempAllProducts = new Array<ProductDataResult>()

        const tempDropDownValue = new ConfigurationDropDown()
        tempDropDownValue.length = '0'
        tempDropDownValue.quantity = '0'

        for (let i = 0; i < allParts.current.length; i++) {

            let currProd = allParts.current[i]
            for (let k = 0; k < currProd.attributes.length; k++) {
                let currAttribute = currProd.attributes[k]
                if (currAttribute.name === "Class: Product Type" && (currAttribute.value === "Hybrid cable assembly | 4-pair"
                    || currAttribute.value === "Hybrid cable assembly | 2-pair" || currAttribute.value === "Hybrid cable assembly")) {
                    tempAllProducts.push(allParts.current[i])
                }
            }

        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power source' || allParts.current[i].productType === 'Panel') {
                tempAllProducts.push(allParts.current[i])
            }
        }


        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power supply') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power cable assembly') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Enclosure') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Backplane') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power transition unit') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power transmitter card') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Module') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Power cable') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Fiber module') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        var ppl1uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_1U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_1U_PARTNUMBER.toString() : ""
        var ppl2uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_2U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_2U_PARTNUMBER.toString() : ""
        var ppl4uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_4U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_4U_PARTNUMBER.toString() : ""


        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].partNumber.match(ppl1uPartNumber)) {
                tempAllProducts.push(allParts.current[i])
            }
            if (allParts.current[i].partNumber.match(ppl2uPartNumber)) {
                tempAllProducts.push(allParts.current[i])
            }
            if (allParts.current[i].partNumber.match(ppl4uPartNumber)) {
                tempAllProducts.push(allParts.current[i])
            }
        }

        for (let i = 0; i < allParts.current.length; i++) {
            if (allParts.current[i].productType === 'Blanking cover') {
                tempAllProducts.push(allParts.current[i])
            }
        }

        tempDropDownValue.cableTypes = tempAllProducts

        setAdditionalProductsDropDown(tempDropDownValue)
    }

    function showBillOfMaterials() {

        /*allAdditionalProdcuts.current = new Array<ProductData>()*/

        let tempIsErrorVisible = false

        for (let i = 0; i < lengthAndCableType.length; i++) {

            const tempDropDownValue = new ConfigurationDropDown()
            tempDropDownValue.length = lengthAndCableType[i].length
            tempDropDownValue.index = i
            tempDropDownValue.cableTypes = lengthAndCableType[i].cableTypes
            tempDropDownValue.selectedPartNumber = lengthAndCableType[i].selectedPartNumber

            if (lengthAndCableType[i].length === '' || lengthAndCableType[i].selectedPartNumber === '') {
                tempIsErrorVisible = true
                setIsErrorSelectionErrorVisible(true)
                break;
            }
        }

        let tempBom = new Array<ProductDataResult>()
        let tempRecommendedProds = new Array<ProductDataResult>()

        setGeneratedBom(tempBom)
        setRecommendedProducts(tempRecommendedProds)

        let mpo16 = 0
        let mpo8 = 0

        let numberOfTransmitterCards = 0;

        if (tempIsErrorVisible === false) {

            setIsErrorSelectionErrorVisible(false)

            for (let i = 0; i < lengthAndCableType.length; i++) {
                for (let j = 0; j < allParts.current.length; j++) {

                    if (allParts.current[j].title === lengthAndCableType[i].selectedPartNumber) {

                        let currProd = allParts.current[j]

                        for (let k = 0; k < currProd.attributes.length; k++) {
                            let currAttribute = currProd.attributes[k]
                            if (currAttribute.name === "Class: Product Type" && (currAttribute.value === "Hybrid cable assembly | 4-pair"
                                || currAttribute.value === "Hybrid cable assembly | 2-pair" || currAttribute.value === "Hybrid cable assembly")) {

                                allParts.current[j].length = Number(lengthAndCableType[i].length)

                                if (allParts.current[j].length >= 10 && allParts.current[j].length <= 150) {
                                    numberOfTransmitterCards = numberOfTransmitterCards + 2
                                }
                                else if (allParts.current[j].length > 150 && allParts.current[j].length <= 350) {
                                    numberOfTransmitterCards = numberOfTransmitterCards + 3
                                }
                                else if (allParts.current[j].length > 350 && allParts.current[j].length <= 500) {
                                    numberOfTransmitterCards = numberOfTransmitterCards + 4
                                }
                            }

                            if ((currAttribute.name === 'Gen: Interface, Connector A' || currAttribute.name === 'Gen: Interface, Connector B') && (currAttribute.value === "MPO-16/APC" || currAttribute.value === "MPO-16/APC Male")) {
                                mpo16 = mpo16 + 1;
                            }

                            if ((currAttribute.name === 'Gen: Interface, Connector A' || currAttribute.name === 'Gen: Interface, Connector B') && (currAttribute.value === "MPO-08/APC" || currAttribute.value === "MPO-08/APC Male")) {
                                mpo8 = mpo8 + 1
                            }
                        }
                    }
                }
            }

            let totalCpcx12Panels = numberOfConvergencePoints / 10

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Power source' || allParts.current[i].productType === 'Panel') {
                    //totalCpcx12Panels = Math.ceil(numberOfConvergencePoints / 10)

                    //allParts.current[i].quantity = totalCpcx12Panels

                    let qtyBasedOnConvergencePoints = Math.ceil(numberOfConvergencePoints / 10)
                    let qtyBasedOnCards = Math.ceil(numberOfTransmitterCards / 24)

                    if (qtyBasedOnCards > qtyBasedOnConvergencePoints) {
                        totalCpcx12Panels = qtyBasedOnCards
                        allParts.current[i].quantity = qtyBasedOnCards
                    }
                    else {
                        allParts.current[i].quantity = qtyBasedOnConvergencePoints
                    }

                    tempBom.push(allParts.current[i])
                }
            }

            for (let i = 0; i < lengthAndCableType.length; i++) {
                for (let j = 0; j < allParts.current.length; j++) {

                    if (allParts.current[j].title === lengthAndCableType[i].selectedPartNumber) {

                        let currProd = allParts.current[j]

                        for (let k = 0; k < currProd.attributes.length; k++) {
                            let currAttribute = currProd.attributes[k]
                            if (currAttribute.name === "Class: Product Type" && (currAttribute.value === "Hybrid cable assembly | 4-pair"
                                || currAttribute.value === "Hybrid cable assembly | 2-pair" || currAttribute.value === "Hybrid cable assembly")) {

                                allParts.current[j].length = Number(lengthAndCableType[i].length)
                                allParts.current[j].quantity = 0

                                let tempDiv: number = (allParts.current[j].length as number) / 10
                                let appendZeroJHM = ''

                                if (tempDiv < 10) {
                                    appendZeroJHM = '0'
                                }

                                allParts.current[j].partNumber = allParts.current[j].title + ' | ' + allParts.current[j].title + '-JHM' + appendZeroJHM  + allParts.current[j].length
                                    + allParts.current[j].title + '-JHM' + appendZeroJHM + allParts.current[j].length
                                tempBom.push(JSON.parse(JSON.stringify(allParts.current[j])))
                            }
                        }
                    }
                }
            }

            //each cpcx 12 has one cpm-3k which is capable of supplying 3000 watts

            let totalPowerSupplyAvailableForSystemFromCpcx12 = Math.ceil(totalCpcx12Panels) * 3000


            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Power supply') {
                    if (numberOfConvergencePoints > 2) {

                        //each enclosure needs 1200 watts of power
                        //number of convergence points = number of enclosures
                        //number of cpcx-12 and the power supplies are also determined based on the number of cards
                        //each cpcx-12 can accomodate only 24 cards, if the crosses then we add another
                        let totalPowerSupplyNeeded = (numberOfConvergencePoints * 1200) - totalPowerSupplyAvailableForSystemFromCpcx12
                        allParts.current[i].quantity = Math.ceil(totalPowerSupplyNeeded / 3000)

                        tempBom.push(allParts.current[i])
                    }

                }
            }

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === "Power cable assembly") {
                    if (numberOfConvergencePoints > 2) {
                        let totalPowerSupplyNeeded = (numberOfConvergencePoints * 1200) - totalPowerSupplyAvailableForSystemFromCpcx12
                        allParts.current[i].quantity = Math.ceil(totalPowerSupplyNeeded / 3000)
                        tempBom.push(allParts.current[i])
                    }
                }
            }

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Enclosure') {
                    allParts.current[i].quantity = numberOfConvergencePoints
                    tempBom.push(allParts.current[i])
                }
            }

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Backplane') {
                    allParts.current[i].quantity = numberOfConvergencePoints
                    tempBom.push(allParts.current[i])
                }
            }

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Power transition unit') {
                    allParts.current[i].quantity = Math.ceil(numberOfTransmitterCards / 24)
                    tempBom.push(allParts.current[i])
                }
            }

            //CTX-6
            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Power transmitter card') {
                    allParts.current[i].quantity = numberOfTransmitterCards
                    tempBom.push(allParts.current[i])
                }
            }

            for (let i = 0; i < allParts.current.length; i++) {
                if (allParts.current[i].productType === 'Power cable') {
                    allParts.current[i].quantity = Math.ceil(numberOfTransmitterCards / 6)
                    tempBom.push(allParts.current[i])
                }
            }

            //for (let i = 0; i < allParts.current.length; i++) {
            //    if (allParts.current[i].productType === 'Module') {
            //        allParts.current[i].quantity = Math.ceil(numberOfTransmitterCards / 5)
            //        tempBom.push(allParts.current[i])
            //    }
            //}

            for (let j = 0; j < allParts.current.length; j++) {
                let currProd = allParts.current[j]
                let isFiberModule = false
                let has8Ports = false;

                for (let k = 0; k < currProd.attributes.length; k++) {
                    let currAttribute = currProd.attributes[k]

                    if ((currAttribute.name === Name.ClassProductType && currAttribute.value === "Fiber module")) {
                        isFiberModule = true
                    }

                    if ((currAttribute.name === Name.TotalPorts && currAttribute.value === "8")) {
                        has8Ports = true
                    }
                }

                if (has8Ports === true && isFiberModule === true && mpo16 > 0) {
                    currProd.quantity = mpo16
                    tempRecommendedProds.push(currProd)
                }

                let isFiberModule8AU = false
                let has4Ports = false;

                for (let k = 0; k < currProd.attributes.length; k++) {
                    let currAttribute = currProd.attributes[k]

                    if ((currAttribute.name === Name.ClassProductType && currAttribute.value === "Fiber module")) {
                        isFiberModule8AU = true
                    }

                    if ((currAttribute.name === Name.TotalPorts && currAttribute.value === "4")) {
                        has4Ports = true
                    }
                }

                if (has4Ports === true && isFiberModule8AU === true && mpo8 > 0) {
                    currProd.quantity = mpo8
                    tempRecommendedProds.push(currProd)
                }
            }

            const PPL: string[] = [];
            let ppl1UCount = 0;
            let ppl2UCount = 0;
            let ppl4UCount = 0;

            //Let the Fiber Count = FC
            //If FC ≥ 8 and FC ≤ 144 then PPL - 1U
            //If FC > 144 and FC ≤ 288 then PPL - 2U
            //If FC > 288 and FC ≤ 576 then PPL - 4U

            //If FC > 576 and FC ≤ 720(576 + 144) then PPL - 4U and PPL - 1U
            //If FC > 576 and FC ≤ 864(576 + 288) then PPL - 4U and PPL - 2U
            //If FC > 576 and FC ≤ 1152(576 + 576) then PPL - 4U and PPL - 4U and so on

            let excessFC = (mpo8 * 8) + (mpo16 * 16);
            var ppl1uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_1U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_1U_PARTNUMBER.toString() : ""
            var ppl2uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_2U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_2U_PARTNUMBER.toString() : ""
            var ppl4uPartNumber = process.env.REACT_APP_SERVER_URL_PPL_4U_PARTNUMBER ? process.env.REACT_APP_SERVER_URL_PPL_4U_PARTNUMBER.toString() : ""

            do {
                if (excessFC >= 8 && excessFC <= 144) {
                    ppl1UCount = ppl1UCount + 1;
                } else if (excessFC > 144 && excessFC <= 288) {
                    ppl2UCount = ppl2UCount + 1;
                } else if (excessFC > 288 && excessFC <= 576) {
                    ppl4UCount = ppl4UCount + 1;
                }
                else if (excessFC > 576) {
                    ppl4UCount = ppl4UCount + 1
                }
                excessFC = excessFC - 576
            }
            while (excessFC >= 8)

            for (let i = 0; i < allParts.current.length; i++) {
                if (ppl1UCount > 0 && allParts.current[i].partNumber.match(ppl1uPartNumber)) {
                    allParts.current[i].quantity = ppl1UCount
                    tempRecommendedProds.push(allParts.current[i])
                }
                if (ppl2UCount > 0 && allParts.current[i].partNumber.match(ppl2uPartNumber)) {
                    allParts.current[i].quantity = ppl2UCount
                    tempRecommendedProds.push(allParts.current[i])
                }
                if (ppl4UCount > 0 && allParts.current[i].partNumber.match(ppl4uPartNumber)) {
                    allParts.current[i].quantity = ppl4UCount
                    tempRecommendedProds.push(allParts.current[i])
                }
            }




            setIsBomVisible(true)
            setGeneratedBom(tempBom)

            setRecommendedProducts(tempRecommendedProds)
            setUiUpdated(tempBom)
        }
    }

    useEffect(() => {
        console.log(generatedBom)
    }, [generatedBom])

    function onChangeAdditionalProductsLength(e: any) {
        let length: number = e.target.value

        //const changedLengthCableType = new ConfigurationDropDown()
        //changedLengthCableType.length = length.toString()

        if (Number(length) % 10 === 0 && length >= 10 && length <= 150) {
            //changedLengthCableType.cableTypes = getTwoAndFourPairValues()
        }
        else if (Number(length) % 10 === 0 && length > 150 && length <= 350) {
            //changedLengthCableType.cableTypes = getFourPairValues()
        }
        else if (Number(length) % 10 === 0 && length > 350 && length <= 550) {
            //changedLengthCableType.cableTypes = getFourPairValues()
        }

        let tempConfigruationDropDownArr = []

        for (let i = 0; i < lengthAndCableType.length; i++) {

            const tempDropDownValue = new ConfigurationDropDown()
            tempDropDownValue.length = lengthAndCableType[i].length
            tempDropDownValue.index = i

            tempDropDownValue.selectedPartNumber = lengthAndCableType[i].selectedPartNumber

            tempConfigruationDropDownArr.push(tempDropDownValue)
        }

        additionalPartSelectedLength.current = length
        //setAdditionalProductsDropDown(tempConfigruationDropDownArr)
    }

    function onChangeAdditionalProductsQuantity(e: any) {
        additionalPartSelectedQuantity.current = e.target.value
    }

    function onChangeAdditionalProductsCableType(e: any) {

        if (e.target.value === "Hybrid cable assembly | 4-pair" || e.target.value === "Hybrid cable assembly | 2-pair"
            || e.target.value === "Hybrid cable assembly") {
            setIsAdditionalProductQty(false)

        }
        else {
            setIsAdditionalProductQty(true)
        }

        //if (e.target.value === "Hybrid cable assembly | 4-pair" || e.target.value === "Hybrid cable assembly | 2-pair"
        //    || e.target.value === "Hybrid cable assembly" && length >= 10 && length <= 150) {
        //    //length should be between >= 10 and <=150
        //    //changedLengthCableType.cableTypes = getTwoAndFourPairValues()
        //}
        //else if (Number(length) % 10 === 0 && length > 150 && length <= 350) {
        //    //changedLengthCableType.cableTypes = getFourPairValues()
        //}
        //else if (Number(length) % 10 === 0 && length > 350 && length <= 550) {
        //    //changedLengthCableType.cableTypes = getFourPairValues()
        //}

        selectedAdditionalProduct.current = additionalProductsDropDown?.cableTypes[e.target.selectedIndex - 1]
        setSelectedAdditionalProductState(additionalProductsDropDown?.cableTypes[e.target.selectedIndex - 1])
    }

    function addAdditionalProduct(e: any) {

        const tempSelectedValues = new Array<ProductDataResult>()
        let isPartExists = false;

        for (let i = 0; i < allAdditionalProdcuts.current.length; i++) {
            if (selectedAdditionalProduct.current?.title === allAdditionalProdcuts.current[i].title &&
                !selectedAdditionalProduct.current.productType?.includes('Hybrid cable assembly')) {
                allAdditionalProdcuts.current[i].quantity = Number(allAdditionalProdcuts.current[i].quantity) +
                    Number(additionalPartSelectedQuantity.current)
                isPartExists = true
                tempSelectedValues.push(allAdditionalProdcuts.current[i])
            }
            else {
                tempSelectedValues.push(allAdditionalProdcuts.current[i])
            }
        }

        let tempSelectedAdditionalProd = new ProductDataResult()

        if (selectedAdditionalProduct.current !== undefined) {
            tempSelectedAdditionalProd = JSON.parse(JSON.stringify(selectedAdditionalProduct.current))
        }

        if (selectedAdditionalProduct.current !== undefined && selectedAdditionalProduct.current?.title.trim() !== '' && isPartExists === false) {

            if (isAdditionalProductQty === false) {
                if ((Number(additionalPartSelectedLength.current) % 10 === 0 && additionalPartSelectedLength.current >= 10 && additionalPartSelectedLength.current <= 150) &&
                    (selectedAdditionalProduct.current.productType === "Hybrid cable assembly | 2-pair" || selectedAdditionalProduct.current.productType === "Hybrid cable assembly | 4-pair")) {
                    tempSelectedAdditionalProd.length = Number(additionalPartSelectedLength.current)
                    tempSelectedAdditionalProd.quantity = 0

                    let tempDiv: number = (additionalPartSelectedLength.current as number) / 10
                    let appendZeroJHM = ''

                    if (tempDiv < 10) {
                        appendZeroJHM = '0'
                    }

                    tempSelectedAdditionalProd.partNumber = selectedAdditionalProduct.current.title + ' | '
                        + selectedAdditionalProduct.current.title + '-JHM' + appendZeroJHM + additionalPartSelectedLength.current
                    tempSelectedValues.push(tempSelectedAdditionalProd)
                }
                else if ((Number(additionalPartSelectedLength.current) % 10 === 0 && additionalPartSelectedLength.current > 150 &&
                    additionalPartSelectedLength.current <= 550) && selectedAdditionalProduct.current.productType === "Hybrid cable assembly | 4-pair") {
                    tempSelectedAdditionalProd.length = Number(additionalPartSelectedLength.current)
                    tempSelectedAdditionalProd.quantity = 0

                    let tempDiv: number = (additionalPartSelectedLength.current as number) / 10
                    let appendZeroJHM = ''

                    if (tempDiv < 10) {
                        appendZeroJHM = '0'
                    }

                    tempSelectedAdditionalProd.partNumber = selectedAdditionalProduct.current.title + ' | '
                        + selectedAdditionalProduct.current.title + '-JHM' + appendZeroJHM + additionalPartSelectedLength.current
                    tempSelectedValues.push(tempSelectedAdditionalProd)
                }
            }
            else {
                if (Number(additionalPartSelectedQuantity.current) > 0) {
                    tempSelectedAdditionalProd.length = 0
                    tempSelectedAdditionalProd.quantity = Number(additionalPartSelectedQuantity.current)
                    tempSelectedValues.push(tempSelectedAdditionalProd)
                }
            }
        }

        allAdditionalProdcuts.current = tempSelectedValues
        setAdditionalProducts(tempSelectedValues)
        setUiUpdated(tempSelectedValues)
    }

    function onClickAddAnother() {
        //const tempSelectedValues = new Array<ConfigurationDropDown>()

        //for (let j = 0; j < additionalProductsDropDown.length; j++) {

        //    let tempAdditionalProductData = new ConfigurationDropDown()
        //    tempAdditionalProductData.cableTypes = new Array<ProductDataResult>()
        //    tempAdditionalProductData.length = '0'
        //    tempAdditionalProductData.selectedPartNumber = ''
        //    tempSelectedValues.push(tempAdditionalProductData)

        //}

        //setAdditionalProductsDropDown(tempSelectedValues)
    }

    function onDeleteAdditionalProduct(e: any, index: any) {
        let deleteAdditionalProds = new Array<ProductDataResult>()

        allAdditionalProdcuts.current.splice(index, 1)

        for (let i = 0; i < allAdditionalProdcuts.current.length; i++) {
            deleteAdditionalProds.push(allAdditionalProdcuts.current[i])
        }


        allAdditionalProdcuts.current = deleteAdditionalProds
        setAdditionalProducts(deleteAdditionalProds)
    }

    function onChangeGeneratedBomQuantity(e: any, index: number) {
        let changedQuantityProd = generatedBom[index]
        changedQuantityProd.quantity = e.target.value
    }

    function onChangeRecommendedBomQuantity(e: any, index: number) {
        let changedQuantityProd = recommendedProducts[index]
        changedQuantityProd.quantity = e.target.value
    }

    function showLearnMoreModal(description: any) {
        setLearnMoreDesc(description)
        setModalShow(true)
    }


    function showMyProductListModal() {
        setShowMyProductList(false)
        //if (isUserAuthenticated.current == false) {
        if (accounts.length <= 0) {
            window.sessionStorage.setItem('numberOfConvergencePoints', numberOfConvergencePoints.toString());
            window.sessionStorage.setItem('lengthAndCableTypes', JSON.stringify(lengthAndCableType))
            window.sessionStorage.setItem('billOfMaterials', JSON.stringify(generatedBom))
            window.sessionStorage.setItem('recommendedProducts', JSON.stringify(recommendedProducts))
            window.sessionStorage.setItem('additionalProducts', JSON.stringify(additionalProducts))
            window.sessionStorage.setItem('savingDataToLocalStorage', JSON.stringify(true))
        }
        else if (accounts.length > 0) {
            setShowMyProductList(true)
        }
        //}
        setInitiateLogin(true)
        //setShowMyProductList(true)
    }

    function handleExportButtonClick() {
        console.log(generatedBom);
        console.log(recommendedProducts);
        console.log(additionalProducts);

        // Create a new Excel Workbook
        var workbook = new ExcelJS.Workbook();

        // Add a Worksheet to the Workbook
        var worksheet = workbook.addWorksheet('Constellation Bill of Materials');

        const titleRow = worksheet.addRow(['Bill of Materials']);
        titleRow.font = { name: 'Calibri', size: 14, bold: true };

        //worksheet.addRow([]);

        const headerRow = worksheet.addRow(['Part Number', 'Length (meters)', 'Quantity', 'Description']);
        headerRow.font = {
            name: 'Calibri', size: 12, bold: true
        };
        headerRow.alignment = { vertical: 'middle', horizontal: 'left' };


        worksheet.getColumn(1).width = 30;
        worksheet.getColumn(2).width = 15;
        worksheet.getColumn(3).width = 15;
        worksheet.getColumn(4).width = 50;


        generatedBom.forEach((value) => {
            if (value.length !== undefined && value.length > 0) {

                const row = worksheet.addRow([value.partNumber, value.length, '-', value.summary]);
                row.alignment = { vertical: 'middle', horizontal: 'left' };

            }
            if (value.quantity !== undefined && value.quantity > 0) {
                const row = worksheet.addRow([value.partNumber, '-', value.quantity, value.summary]);
                row.alignment = { vertical: 'middle', horizontal: 'left' };
            }
        });

        //worksheet.addRow([]); // Add an empty row between data sets
        //worksheet.addRow([]); // Add an empty row between data sets
        worksheet.addRow([]); // Add an empty row between data sets

        // Add recommended products
        const recommendedProductsTitleRow = worksheet.addRow(['Recommended Products']);
        recommendedProductsTitleRow.font = { name: 'Calibri', size: 14, bold: true };
        //worksheet.addRow([]); // Add an empty row between data sets


        const headerRow1 = worksheet.addRow(['Part Number', 'Length (meters)', 'Quantity', 'Description']);
        headerRow1.font = {
            name: 'Calibri', size: 12, bold: true
        };



        recommendedProducts.forEach((product) => {

            if (product.length !== undefined && product.length > 0) {
                const row = worksheet.addRow([product.partNumber, product.length, '-', product.summary]);
                row.alignment = { vertical: 'middle', horizontal: 'left' };
            }
            if (product.quantity !== undefined && product.quantity > 0) {
                const row = worksheet.addRow([product.partNumber, '-', product.quantity, product.summary]);
                row.alignment = { vertical: 'middle', horizontal: 'left' };
            }
            // worksheet.addRow([product.partNumber, product.length, product.quantity, product.summary]);
        });

        worksheet.addRow([]); // Add an empty row between data sets
        //worksheet.addRow([]); // Add an empty row between data sets
        //worksheet.addRow([]); // Add an empty row between data sets

        // Add additional products

        if (additionalProducts.length > 0) {

            const additionalProductsTitleRow = worksheet.addRow(['Additional Products']);
            additionalProductsTitleRow.font = { name: 'Calibri', size: 14, bold: true };

            const headerRow2 = worksheet.addRow(['Part Number', 'Length (meters)', 'Quantity', 'Description']);
            headerRow2.font = {
                name: 'Calibri', size: 12, bold: true
            };

            additionalProducts.forEach((product) => {


                if (product.length !== undefined && product.length > 0) {
                    const row = worksheet.addRow([product.partNumber, product.length, '-', product.summary]);
                    row.alignment = { vertical: 'middle', horizontal: 'left' };
                }
                if (product.quantity !== undefined && product.quantity > 0) {
                    const row = worksheet.addRow([product.partNumber, '-', product.quantity, product.summary]);
                    row.alignment = { vertical: 'middle', horizontal: 'left' };
                }
                // worksheet.addRow([product.partNumber, product.length, product.quantity, product.summary]);
            });
        }
        // Generate Excel File with given name
        workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'ConstellationBoM.xlsx');
        });
    }

    function onConvergencePointsCancelClick(event: any) {
        if (txtConvergencePoints.current !== null) {
            txtConvergencePoints.current.value = previousConvergencePoints.current.toString()
            setNumberOfConvergencePoints(previousConvergencePoints.current)
        }
        setSmAlert(false)
    }

    function onConvergencePointsYesClick(event: any) {
        setNumberOfConvergencePoints(0)
        resetConfiguration()
        setSmAlert(false)
    }

    function isAuthenticatedFromMPL(isAuthenticated: any) {
        console.log(isAuthenticated)
        isUserAuthenticated.current = isAuthenticated
    }

    function onSignInCallBack() {
        const setVal = async () => {
            setShowMyProductList(false)
        };
        setVal();

        console.log("onSignInCallBack");
        window.history.replaceState(
            {},
            document.title,
            window.location.pathname
        )
        //window.sessionStorage.setItem("AuthorizationToken", (_user) ? _user?.access_token : "");
        //setShowMyProductList(true)
        if (window.sessionStorage.getItem('savingDataToLocalStorage') === 'true') {

            //props.updateFromConfigurator(false)

            let localNumberOfConvergencePoints = window.sessionStorage.getItem('numberOfConvergencePoints')
            setNumberOfConvergencePoints(localNumberOfConvergencePoints !== null ? parseInt(localNumberOfConvergencePoints) : 0)

            if (txtConvergencePoints.current !== null)
                txtConvergencePoints.current.value = (localNumberOfConvergencePoints !== null ? parseInt(localNumberOfConvergencePoints) : 0).toString();

            let localLengthAndCableType = window.sessionStorage.getItem('lengthAndCableTypes')

            if (localLengthAndCableType !== null) {
                var tempLengthCable = JSON.parse(localLengthAndCableType)
                const tempConfigruationDropDownArr = new Array<ConfigurationDropDown>()

                for (let i = 0; i < tempLengthCable.length; i++) {

                    const tempDropDownValue = new ConfigurationDropDown()
                    tempDropDownValue.length = tempLengthCable[i].length
                    tempDropDownValue.index = i
                    tempDropDownValue.cableTypes = tempLengthCable[i].cableTypes
                    tempDropDownValue.selectedPartNumber = tempLengthCable[i].selectedPartNumber

                    tempConfigruationDropDownArr.push(tempDropDownValue)
                }
                setTimeout(() => setLengthAndCableType(tempConfigruationDropDownArr), 1);

            }

            let localbillOfMaterials = window.sessionStorage.getItem('billOfMaterials')
            setTimeout(() => setGeneratedBom(localbillOfMaterials !== null ? JSON.parse(localbillOfMaterials) : new Array<ProductDataResult>()), 1);

            let localrecommendedProducts = window.sessionStorage.getItem('recommendedProducts')
            setTimeout(() => setRecommendedProducts(localrecommendedProducts !== null ? JSON.parse(localrecommendedProducts) : new Array<ProductDataResult>()), 1);

            let localadditionalProducts = window.sessionStorage.getItem('additionalProducts')
            setTimeout(() => setAdditionalProducts(localadditionalProducts !== null ? JSON.parse(localadditionalProducts) : new Array<ProductDataResult>()), 1);

            //setTimeout(() => props.updateFromConfigurator(true), 1);

            window.sessionStorage.removeItem('numberOfConvergencePoints')
            window.sessionStorage.removeItem('lengthAndCableTypes')
            window.sessionStorage.removeItem('billOfMaterials')
            window.sessionStorage.removeItem('recommendedProducts')
            window.sessionStorage.removeItem('additionalProducts')
            window.sessionStorage.removeItem('savingDataToLocalStorage')

            showBillOfMaterials()

            const setVal = async () => {
                console.log(initiateLogin);
                console.log(accounts.length);

                setShowMyProductList(true)
            };
            setVal();

            
        }
        //window.location.reload();
    }

    useEffect(() => {
        console.log(showMyProductList)
    }, [showMyProductList])

    const { instance, accounts, inProgress } = useMsal();
    const msalInstance = props.b2cMsalInstance;

    const isAuthenticated = useIsAuthenticated();
    const activeAccount = msalInstance.getAllAccounts();
    const [initiateLogin, setInitiateLogin] = useState(false);
    const clientID = process.env.REACT_APP_B2C_CLIENTID ? process.env.REACT_APP_B2C_CLIENTID.toString() : ""

    const authRequest = {
        scopes: [clientID,"offline_access", "openid","profile"]
    };

    useEffect(() => {
        console.log("isAuthenticated: " + activeAccount)
    }, [activeAccount])
    useEffect(() => {
        console.log("isAuthenticated: " + activeAccount)
    }, [isAuthenticated])
    useEffect(() => {
        if (accounts.length > 0) {
            onSignInCallBack()
        } else if (inProgress === "login") {
           
        }
    }, [accounts, inProgress, instance])

return (
    <div>

        <Modal
            show={smAlert}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}>
            <Modal.Header>
                <Modal.Title>{smAlertMessageTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {smAlertMessageDesc}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onConvergencePointsCancelClick}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConvergencePointsYesClick}>Yes</Button>
            </Modal.Footer>
        </Modal>
        <LearnMoreModal
            show={modalShow}
            onHide={() => setModalShow(false)}
            title="Learn More" description={learnMoreDesc} />
        {initiateLogin === true &&
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest}>
                <div>
                </div>
                {/*    </AuthProvider>*/}
            </MsalAuthenticationTemplate>
        }
        {
            accounts !== null && accounts.length > 0 &&
            <div>
                <MyProductList isAuthenticatedFromMPL={isAuthenticatedFromMPL}
                    show={showMyProductList} lengthAndCableType={lengthAndCableType}
                    onHide={() => setShowMyProductList(false)}
                    title="Save to My Product List" generatedBom={generatedBom}
                    recommendedProducts={recommendedProducts} additionalProducts={additionalProducts} accounts={accounts} />
            </div>
        }
        <div className="row" style={{
            backgroundColor: "#f4f4f4", paddingTop: "1%", paddingLeft: "1%", paddingBottom: "1%", paddingRight: "1%"
        }}>
            <div className="col-md-5">
                <h6>Convergence Points</h6>
                <hr />
                <p><strong>Number of Convergence Points</strong></p>

                <div className='row pl-3'>
                    <input type="number" className="form-control col-md-2"
                        onFocus={(e) => onFocusConvergencePoints(e)} onBlur={(e) => onSubmitConvergencePoints(e)}
                        ref={txtConvergencePoints} defaultValue='0'
                        aria-describedby="helpBlock" onChange={onChangeNumberOfConvergencePoints} />
                    <button type="button" className="btn btn-primary ml-3"
                        onClick={onClickCreateConvergencePoints}>Confirm</button>
                </div>
                {newNumberOfConvergencePoints !== undefined && newNumberOfConvergencePoints > 0 &&
                    <div>
                        <h6 className="mt-4">AC Input cables</h6>
                        <hr />
                        <input className="form-control" type="text" placeholder="L6-20P to SAF-D-GRID Power Cord" readOnly /></div>
                }
            </div>


            <div className="col-md-1">
            </div>

            <div className="col-md-6">
                <h6>Cable Length and Cable Type</h6>
                <hr />
                <p>Enter the cable distance and cable type for each unit.</p>
                <p><em>Length is in meters, minimum length 10 m, max length 500 m</em></p>

                <p>For cable distances greather than 500 m,
                    <a href={supportPageLink} style={{ display: 'contents' }} target='_blank' type="button" className="btn btn-link">
                        contact us for assistance</a></p>
                {newNumberOfConvergencePoints !== undefined && newNumberOfConvergencePoints > 0 &&
                    <div className="row">

                        <div className="col-md-2 d-flex align-items-center">
                            <p><strong>Unit</strong></p>
                        </div>

                        <div className="col-md-2 d-flex align-items-center">
                            <p><strong>Length (meters)</strong></p>
                        </div>

                        <div className="col-md-8 d-flex align-items-center">
                            <p ><strong>Cable Type</strong></p>
                        </div>
                        {
                            lengthAndCableType.map((value, index) => {
                                return (
                                    <div className="row mb-3">
                                        <div className="col-md-2" >
                                            <input style={{ textAlign: 'center' }} className="form-control" type="text"
                                                placeholder={(index + 1).toString()} width="100px" readOnly />
                                        </div>

                                        <div className="col-md-2">
                                            <input type="number" defaultValue={value.length} className="form-control" aria-describedby="helpBlock"
                                                onChange={(e) => onChangeLength(e, index, value)} onBlur={(e) => onSubmitCableTypeLength(e)}
                                                onFocus={(e) => onFocusCableTypeLength(e, index, value)} />
                                        </div>

                                        <div className="col-md-8">
                                            <select className="custom-select" value={value.selectedPartNumber} onChange={(e) => onChangeCableType(e, index)}>
                                                <option>Select</option>
                                                {value.cableTypes.map((cableTypeVal, cableTypeIndex) => {
                                                    return (
                                                        <option value={cableTypeVal.title}>{cableTypeVal.summary}</option>)
                                                })}
                                            </select>
                                        </div>
                                        {
                                            ((Number(value.length) < 10) && Number(value.length) !== 0) && <p className="displayBlock text-danger mt-1 ml-3">Length should be between 10m & 500m and a multiple of 10</p>
                                        }
                                        {
                                            ((Number(value.length) > 500)) && <p className="mt-1 ml-3 ">For cable distances greather than 500m, <button style={{ display: 'contents' }} type="button" className="btn btn-link">
                                                contact us for assistance</button></p>
                                        }
                                        {
                                            ((Number(value.length) > 10) && (Number(value.length) % 10 !== 0)) && <p className="mt-1 ml-3 text-danger">Length Should be a multiple of 10</p>
                                        }
                                    </div>
                                )

                            })
                        }

                    </div>
                }
                {newNumberOfConvergencePoints !== undefined && newNumberOfConvergencePoints > 0 &&
                    <div className="button-container row">

                        <button type="button" className="btn btn-primary" onClick={showBillOfMaterials}>Configure and Show Bill of Materials</button>
                        <button type="button" className="btn btn-secondary mr-3" onClick={resetConfiguration}>Reset</button>

                    </div>

                }
                <p className={isErrorSelectionErrorVisible == true ? "displayBlock text-danger mt-3" : "hideBlock text-danger mt-3"} >Please enter Lengths and select Cable Types for all Convergence Points</p>
            </div>
        </div>
        <div className={isBomVisible == true ? "displayBlock mt-3 p-3" : "hideBlock mt-3"} style={{ border: '3px solid #999' }}>
            <h4 className="mt-4">
                Bill of Materials
            </h4>
            <hr />
            <p>The first Qty Column displays the number of products for the configuration to function.</p>
            <div className="table-responsive" id='billOfMaterials'>
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Part Number</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Length (meters)</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Qty</th>

                            {/*<th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>*/}
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {generatedBom.map((value, index) => {

                            return (
                                <tr style={{ textAlign: "left" }}>
                                    {value.length !== undefined && value.length > 0 &&
                                        <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}><a href={value.url.originalString} className='text-primary'
                                            style={{ fontWeight: 'bold' }} target="_blank">{value.partNumber}</a></td>
                                    }
                                    {value.quantity !== undefined && value.quantity > 0 &&
                                        <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}><a href={value.url.originalString} className='text-primary'
                                            style={{ fontWeight: 'bold' }} target="_blank">{value.title}</a></td>

                                    }
                                    {value.length !== undefined && value.length > 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{value.length.toString()}</td>
                                    }

                                    {value.length === undefined || value.length === 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{'-'}</td>
                                    }

                                    {value.quantity !== undefined && value.quantity > 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{value.quantity.toString()}</td>
                                    }

                                    {value.quantity === undefined || value.quantity === 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{'-'}</td>
                                    }
                                    {/*<td className="pt-1 pb-1 col-1" style={{ padding: "0px" }}>*/}
                                    {/*    <input type="number" className="form-control col-8" aria-describedby="helpBlock" value={value.quantity.toString()} onChange={(e) => onChangeGeneratedBomQuantity(e, index)} />*/}
                                    {/*</td>*/}
                                    <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}>{value.summary}</td>

                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <div className="table-responsive" id='billOfMaterialsTitle' style={{ display: "none" }}>
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Bill of Materials</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive" id='recommendedProductsTitle' style={{ display: "none" }}>
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Recommended Products</th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div className="table-responsive" id='additionalProductsTitle' style={{ display: "none" }}>
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Additional Products</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </div>
        <div className={isBomVisible == true ? "displayBlock mt-3" : "hideBlock mt-3"}>
            <h4 className="mt-4">
                Recommended Products
            </h4>
            <hr />
            <p>The first Qty Column displays the number of products recommended for the system.</p>
            <div className="table-responsive" id='recommendedProducts'>
                <table className="table table-striped">
                    <thead >
                        <tr>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Part Number</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Qty</th>

                            {/*<th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>*/}
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recommendedProducts.map((value, index) => {
                            return (
                                <tr style={{ textAlign: "left" }}>

                                    <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}><a href={value.url.originalString} className='text-primary' style={{ fontWeight: 'bold' }} target="_blank">{value.title}</a></td>
                                    <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}><strong>{value.quantity.toString()}</strong></td>
                                    {/*<td className="pt-1 pb-1 col-1" style={{ padding: "0px" }}>*/}
                                    {/*    <input type="number" className="form-control col-8" aria-describedby="helpBlock" value={value.quantity.toString()} onChange={(e) => onChangeRecommendedBomQuantity(e, index)} />*/}
                                    {/*</td>*/}
                                    {/*<td className="pt-2 pb-2 col-4" style={{ padding: "0px" }}>{value.partDescription}</td>*/}
                                    <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}>{value.summary}</td>
                                </tr>)
                        })}
                    </tbody>
                </table>
            </div>
        </div>
        <div className={isBomVisible == true ? "displayBlock mt-3" : "hideBlock mt-3"}>
            <h4 className="mt-4">
                Add additional products
            </h4>
            <hr />
            <div className="row">

                <div className="col-md-6 d-flex align-items-center">
                    <p ><strong>Product</strong></p>
                </div>
                {isAdditionalProductQty === false &&
                    <div className="col-md-1 d-flex align-items-center">
                        <p><strong>Length (meters)</strong></p>
                    </div>
                }
                {isAdditionalProductQty === true &&
                    <div className="col-md-1 d-flex align-items-center">
                        <p><strong>Qty</strong></p>
                    </div>
                }
            </div>
            <div className="row mb-1">
                <div className="col-md-6">
                    <select className="custom-select" onChange={(e: any) => onChangeAdditionalProductsCableType(e)}>
                        <option>Select</option>
                        {additionalProductsDropDown !== undefined && additionalProductsDropDown.cableTypes.length > 0
                            && additionalProductsDropDown.cableTypes.map((cableTypeVal, cableTypeIndex) => {
                                return (


                                    <option value={cableTypeVal.productType ? cableTypeVal.productType : ""} >
                                        {cableTypeVal.partName !== null ? cableTypeVal.partName + ' | ' + cableTypeVal.summary : cableTypeVal.summary}
                                    </option>

                                )
                            })}
                    </select>
                </div>
                {isAdditionalProductQty === false &&
                    <div className="col-md-1">
                        <input type="number" className="form-control" aria-describedby="helpBlock"
                            onChange={(e) => onChangeAdditionalProductsLength(e)} onFocus={(e) => onFocusAdditionalPrdQty(e)} onBlur={(e) => onSubmitadditionalPrdQty(e)}
                            defaultValue='0' />
                    </div>
                }
                {isAdditionalProductQty === true &&
                    <div className="col-md-1">
                        <input type="number" className="form-control" aria-describedby="helpBlock"
                            onChange={(e) => onChangeAdditionalProductsQuantity(e)} onFocus={(e) => onFocusAdditionalPrdQty(e)} onBlur={(e) => onSubmitadditionalPrdQty(e)}
                            defaultValue='0' />
                    </div>
                }
                <button type="button" className="btn btn-primary ml-2" onClick={addAdditionalProduct}>Add</button>
                {/*<button type="button" className="btn btn-link ml-3" onClick={onClickAddAnother}>Add another</button>*/}

            </div>
            <div className="row mb-3">
                {/*if (e.target.value === "Hybrid cable assembly | 4-pair" || e.target.value === "Hybrid cable assembly | 4-pair"*/}
                {/*|| e.target.value === "Hybrid cable assembly") {*/}
                {/*    setIsAdditionalProductQty(false)*/}

                {/*}*/}
                {/*else {*/}
                {/*    setIsAdditionalProductQty(true)*/}
                {/*}*/}
                {
                    (selectedAdditionalProductState !== undefined && selectedAdditionalProductState.productType === "Hybrid cable assembly | 2-pair")
                    && <p className="displayBlock text-warning mt-1 ml-3">Length should be between 10m & 150m and a multiple of 10</p>
                }
                {
                    (selectedAdditionalProductState !== undefined && selectedAdditionalProductState.productType === "Hybrid cable assembly | 4-pair")
                    && <p className="displayBlock text-warning mt-1 ml-3">Length should be between 10m & 550m and a multiple of 10</p>
                }
                {/*{*/}
                {/*    (selectedAdditionalProduct.current?.productType === "Hybrid cable assembly | 4-pair")*/}
                {/*    && <p className="mt-1 ml-3 ">For cable distances greather than 500m, <button style={{ display: 'contents' }} type="button" className="btn btn-link">*/}
                {/*        contact us for assistance</button></p>*/}
                {/*}*/}
                {/*{*/}
                {/*    ((Number(additionalPartSelectedLength.current) > 10) && (Number(additionalPartSelectedLength.current) % 10 !== 0)) && <p className="mt-1 ml-3 text-danger">Length Should be a multiple of 10</p>*/}
                {/*}*/}
            </div>
            <div className="table-responsive mt-4" id='additionalProducts'>
                <table className="table table-striped">

                    <thead>
                        <tr >
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Part Number</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Length (meters)</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Qty</th>

                            {/*<th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>*/}
                            <th style={{ padding: "0px", paddingBottom: "5px" }}>Description</th>
                            <th style={{ padding: "0px", paddingBottom: "5px" }}></th>
                        </tr>
                    </thead>
                    <tbody >
                        {additionalProducts.map((value, index) => {
                            return (
                                <tr style={{ textAlign: "left" }}>
                                    {value.length !== undefined && value.length > 0 &&
                                        <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}><a href={value.url.originalString} className='text-primary' style={{ fontWeight: 'bold' }} target="_blank">{value.partNumber}</a></td>
                                    }
                                    {value.quantity !== undefined && value.quantity > 0 &&
                                        <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}><a href={value.url.originalString} className='text-primary' style={{ fontWeight: 'bold' }} target="_blank">{value.title}</a></td>

                                    }
                                    {value.length !== undefined && value.length > 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{value.length.toString()}</td>
                                    }

                                    {value.length === undefined || value.length === 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{'-'}</td>
                                    }

                                    {value.quantity !== undefined && value.quantity > 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{value.quantity.toString()}</td>
                                    }

                                    {value.quantity === undefined || value.quantity === 0 &&
                                        <td className="pt-2 pb-2 col-2" style={{ padding: "0px" }}>{'-'}</td>
                                    }

                                    <td className="pt-2 pb-2 col-3" style={{ padding: "0px" }}>{value.summary}</td>
                                    <td className="pt-2 pb-2 col-1" style={{ padding: "0px" }}>
                                        <button type="button" className="btn btn-secondary" onClick={(e) => onDeleteAdditionalProduct(e, index)}>Delete</button>
                                    </td>

                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className="mt-4 row">
                <button type="button" className="btn btn-primary" onClick={(e) => showMyProductListModal()}>Save to My Products List</button>
                {/*<p style={{ marginTop:"0.5rem" }}>{props.customDescription}*/}
                {/*    <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)} className='text-primary' style={{ fontWeight: 'bold', marginLeft: '0.2rem' }}>Learn More</a>*/}
                {/*    <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)}><span className="info"> i</span></a>*/}
                {/*</p>*/}
                <div >
                    <p style={{ marginLeft: "0.75rem" }}>{props.customDescription}
                        {/*<a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)} className='text-primary' style={{ fontWeight: 'bold', marginLeft: '0.2rem' }}>Learn More</a>*/}
                        <a href="javascript:void(0)" onClick={(e) => showLearnMoreModal(props.LearnMore)}><span className="info"> i</span></a>
                    </p>
                </div>
                <button type="button" onClick={handleExportButtonClick} className="btn btn-secondary ml-3" >Save as Excel</button>
                <DownloadPDFButton generatedBom={generatedBom} recommendedProducts={recommendedProducts} additionalProducts={additionalProducts} />
                {/*<ExcelFile element={<button>Download Data</button>}>*/}
                {/*    <ExcelSheet data={dataSet1} name="Employees">*/}
                {/*        <ExcelColumn label="Name" value="name" />*/}
                {/*        <ExcelColumn label="Wallet Money" value="amount" />*/}
                {/*        <ExcelColumn label="Gender" value="sex" />*/}
                {/*        <ExcelColumn label="Marital Status"*/}
                {/*            value={(col) => col.is_married ? "Married" : "Single"} />*/}
                {/*    </ExcelSheet>*/}
                {/*    <ExcelSheet data={dataSet2} name="Leaves">*/}
                {/*        <ExcelColumn label="Name" value="name" />*/}
                {/*        <ExcelColumn label="Total Leaves" value="total" />*/}
                {/*        <ExcelColumn label="Remaining Leaves" value="remaining" />*/}
                {/*    </ExcelSheet>*/}
                {/*</ExcelFile>*/}
            </div>
        </div>
    </div>
)
}