export function Instructions(props: any) {
    return (
        <div className="row mt-3 pb-4 mb-4 mb">
           
            <div className="col-md-6">
                <h4>How to use this configurator:</h4>
                <p>This configurator will generate a bill of materials for a Constellation power delivery system. Simply select the quantity of convergence points, adjust the length and select the cable from the menu. Additional products can also be added such as bulk cable without end connections. Once selections have been made, a bill of materials will be generated and will show the minimum amount of items required to configure a system. The configurator allows for extra product to be added or subtracted.</p>
            </div>

            <div className="col-md-1 mb-4 mb mt-3">
            </div>

            <div className="col-md-5 mb-4 mb mt-3">
                <img alt="Constellation Power Delivery System Instructions" src="Assets/ConstellationInstructions.png" />
            </div>
        </div>
        );
}