import './App.css';
import Header from './Header';
import Footer from './Footer';
import Breadcrumbs from './Components/Breadcrumbs/Breadcrumbs';
import { Instructions } from './Components/Instructions/Instructions';
import { Configurator } from './Components/Configurator/Configurator';
import Analytics from './Analytics';
import { MsalProvider } from '@azure/msal-react';

//const Pages = () => {
//    const { instance, inProgress, accounts } = useMsal();
//    const [apiData, setApiData] = useState(null);

//    useEffect(() => {
//        const accessTokenRequest = {
//            scopes: [process.env.REACT_APP_B2C_READSCOPE ? process.env.REACT_APP_B2C_READSCOPE.toString() : "", process.env.REACT_APP_B2C_WRITESCOPE ? process.env.REACT_APP_B2C_WRITESCOPE.toString() : ""],
//            account: accounts[0],
//        };
//        if (!apiData && inProgress === InteractionStatus.None) {
//            instance
//                .acquireTokenSilent(accessTokenRequest)
//                .then((accessTokenResponse) => {
//                    // Acquire token silent success
//                    let accessToken = accessTokenResponse.accessToken;
//                    // Call your API with token
//                    //callApi(accessToken).then((response) => {
//                    //    setApiData(response);
//                    //});
//                })
//                .catch((error) => {
//                    if (error instanceof InteractionRequiredAuthError) {
//                        instance.acquireTokenRedirect(accessTokenRequest);
//                    }
//                    console.log(error);
//                });
//        }
//    }, [instance, accounts, inProgress, apiData]);
//};


const Pages = ({ instance }: { instance: any }) => {
    return (
        <div className="App">
            <Header />
            <Analytics />
            <Breadcrumbs />
            <div className="container">
                <Instructions />
                <Configurator b2cMsalInstance={instance} />
            </div>
            <Footer />
        </div>
    );
}


const App = ({ instance }: { instance: any }) => {
    return (
        <MsalProvider instance={instance}>
            <Pages instance={instance} />
        </MsalProvider>
    );
};

export default App;