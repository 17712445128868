
export class MyProductListCart {

    $id: string;
    projects: string[];
    isSuccess: boolean;
    message: string;

    constructor() {
        this.$id = ""
        this.projects = []
        this.isSuccess = false
        this.message = ""
    }
}

export class MyProductListLineItem {

    UserName: string;
    ProductListNames: string[];
    ItemList: LineItem[];
    constructor() {
        this.UserName = ""
        this.ProductListNames = []
        this.ItemList = new Array<LineItem>()
    }
}

export class LineItem {
    code: string;
    quantity: string;
    PartNumber: string;
    ConfPart: Array<string>;
    constructor() {
        this.code = ""
        this.quantity = ""
        this.PartNumber = ""
        this.ConfPart = new Array<string>
    }
}

